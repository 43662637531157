import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/cn'

interface TabProps {
	setTab: (tab: string) => void
	selectedTab: string
	tabs: string[]
	variant?: 'default' | 'colored'
	className?: string
}

export const Tabs = ({ tabs, selectedTab, setTab, variant = 'default', className }: TabProps) => {
	const { t } = useTranslation()
	return (
		<nav
			className={cn('-mb-px flex gap-x-3 md:gap-x-8', {
				'max-md:flex-wrap max-md:justify-center max-md:gap-y-1': tabs.length > 2
			})}
			aria-label="Tabs">
			{tabs.map(tab => (
				<div
					key={tab}
					onClick={() => setTab(tab)}
					className={cn(
						`group pb-2 md:pb-4 max-md:text-xs font-bold flex flex-col gap-y-2 items-center cursor-pointer justify-center md:text-base text-sm whitespace-nowrap order-2`,
						tab === selectedTab
							? variant === 'colored'
								? 'border-primary-light text-primary-light border-b'
								: 'border-primary-light text-primary-light border-b'
							: 'text-primary',
						className
					)}>
					<p>{t(`tabs.labels.${tab}`)}</p>
				</div>
			))}
		</nav>
	)
}

export const PillTabs = ({ tabs, selectedTab, setTab }: TabProps) => {
	const { t } = useTranslation()
	return (
		<div>
			<nav className="flex w-full bg-cards rounded-full bg-[#D3E3F14D]" aria-label="Tabs">
				{tabs.map(tab => (
					<div
						key={tab}
						onClick={() => setTab(tab)}
						className={cn(
							'group grow font-medium  py-1.5 px-3 md:px-4 flex items-center cursor-pointer justify-center text-xs md:text-sm order-2',
							tab === selectedTab
								? 'bg-tertiary text-white rounded-full'
								: 'text-[#001F3F] rounded-full'
						)}>
						<p>{t(`tabs.labels.${tab}`)}</p>
					</div>
				))}
			</nav>
		</div>
	)
}
