import { ScheduleForm } from '@/pages/companies/edit'
import axios from '@/services/axios'

const getAllCompanies = (): Promise<Company[]> => {
	return axios.get('/company').then(response => response.data)
}

const getCompanyById = (companyId: string): Promise<Company> => {
	return axios.get(`/company/${companyId}`).then(response => response.data)
}

const createCompany = (data: FormData) => {
	return axios
		.post('/company', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateCompany = (companyId: string, data: FormData | Object, isCoverImage: boolean) => {
	return axios.put(`/company/${companyId}?slider=${isCoverImage}`, data)
}

const updateCompanyStatus = (companyId: string, data: { status: string }) => {
	return axios.post(`/updateStatus/${companyId}`, data)
}

const deleteCompany = (companyId: string) => {
	return axios.delete(`/company/${companyId}`)
}

const getCompanyLogo = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/logo/company?company_name=${id}`).then(response => response.data)
}

const getCompanySlider = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/slider/company?company_name=${id}`).then(response => response.data)
}

const getCompanySchedule = (companyId: string): Promise<Schedule[]> => {
	return axios.get(`/company/scedule/${companyId}`).then(response => response.data)
}

const createCompanySchedule = (data: ScheduleForm) => {
	return axios.post(`/scedule`, data).then(response => response.data)
}

const updateCompanySchedule = (scheduleId: string, data: ScheduleForm) => {
	return axios.put(`/scedule/${scheduleId}`, data).then(response => response.data)
}

const companyService = {
	getAllCompanies,
	getCompanyById,
	createCompany,
	createCompanySchedule,
	updateCompanySchedule,
	getCompanyLogo,
	getCompanySlider,
	getCompanySchedule,
	updateCompany,
	updateCompanyStatus,
	deleteCompany
}

export default companyService
