import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import {
	ColumnDef,
	SortingState,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

import { cn } from '@/utils/cn'

interface TableProps {
	columns: ColumnDef<Object, any>[]
	data: Array<Object>
	hasActionColumn?: boolean
	enableSorting?: boolean
	enablePagination?: boolean
	itemsPerPage?: number
	className?: string
}

export const Table = ({
	columns,
	data,
	itemsPerPage = 10,
	hasActionColumn = false,
	enableSorting = true,
	enablePagination = true,
	className
}: TableProps) => {
	const navigate = useNavigate()

	const [sorting, setSorting] = useState<SortingState>([])

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		state: {
			sorting: sorting
		},
		initialState: {
			pagination: {
				pageSize: itemsPerPage
			}
		},
		sortDescFirst: false,
		onSortingChange: setSorting
	})

	const renderSortingIcons = (headerId: string) => {
		if (!enableSorting) {
			return null
		}

		const isColumnSorted = sorting[0]?.id === headerId
		const isDescending = sorting[0]?.desc

		if (!isColumnSorted) {
			return (
				<>
					<ChevronUpIcon className={cn('h-3 w-3 cursor-pointer')} />
					<ChevronDownIcon className={cn('h-3 w-3 cursor-pointer')} />
				</>
			)
		}

		return (
			<>
				<ChevronUpIcon
					className={cn('h-3 w-3 cursor-pointer', {
						'stroke-2 stroke-white': !isDescending,
						'text-gray-400': isDescending
					})}
				/>
				<ChevronDownIcon
					className={cn('h-3 w-3 cursor-pointer', {
						'stroke-2 stroke-white': isDescending,
						'text-gray-400': !isDescending
					})}
				/>
			</>
		)
	}

	let pageArray = [0, 1, 2]
		.map(v => table.getState().pagination.pageIndex + 1 + v)
		.filter(page => page > 0 && page <= table.getPageCount())

	if (table.getState().pagination.pageIndex > table.getPageCount() - 4) {
		pageArray = [-1, 0, 1, 2]
			.map(v => table.getState().pagination.pageIndex + 1 + v)
			.filter(page => page > 0 && page <= table.getPageCount())
	}
	if (table.getState().pagination.pageIndex > table.getPageCount() - 3) {
		pageArray = [-2, -1, 0, 1, 2]
			.map(v => table.getState().pagination.pageIndex + 1 + v)
			.filter(page => page > 0 && page <= table.getPageCount())
	}
	if (table.getState().pagination.pageIndex > table.getPageCount() - 2) {
		pageArray = [-3, -2, -1, 0, 1, 2, 3]
			.map(v => table.getState().pagination.pageIndex + 1 + v)
			.filter(page => page > 0 && page <= table.getPageCount())
	}

	return (
		<table
			className={cn(
				'max-md:block max-md:whitespace-nowrap border-separate w-full overflow-x-auto border-spacing-0',
				className
			)}>
			<thead className="bg-[#C6C7F8]">
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header, index) => (
							<th
								onClick={enableSorting ? header.column.getToggleSortingHandler() : undefined}
								className={cn(
									'cursor-pointer py-3 px-4 text-left text-primary font-domine text-sm font-bold',
									{
										'rounded-tl-xl border-l border-[#C6C7F8]': index === 0,
										'rounded-tr-xl border-r border-[#C6C7F8]':
											index === headerGroup.headers.length - 1
									}
								)}
								key={header.id}>
								{!(hasActionColumn && index === headerGroup.headers.length - 1) && (
									<div className="flex justify-between gap-x-2 items-center">
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
										{{ asc: '', desc: '' }[header.column.getIsSorted() as string] ?? null}
										<div className="flex flex-col">{renderSortingIcons(header.id)}</div>
									</div>
								)}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row, rowIndex) => (
					<tr className="bg-white" key={row.id}>
						{row.getVisibleCells().map((cell, index) => (
							<td
								className={cn('py-3 px-4 text-primary text-sm border-b border-[#1C1C1C1A]', {
									'border-l border-[#1C1C1C1A]': index === 0,
									'border-r border-[#1C1C1C1A]': index === row.getVisibleCells().length - 1,
									'rounded-bl-xl': index === 0 && rowIndex === table.getRowModel().rows.length - 1,
									'rounded-br-xl':
										index === row.getVisibleCells().length - 1 &&
										rowIndex === table.getRowModel().rows.length - 1
								})}
								key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
			</tbody>
			{enablePagination && (
				<tfoot>
					<tr>
						<td colSpan={columns.length}>
							<div className="flex items-center py-5">
								<div className="flex sm:flex-1 sm:items-center sm:justify-between ">
									<div className="w-full sm:w-auto ml-auto text-right mt-2 sm:mt-0">
										<nav className="isolate inline-flex gap-x-2 rounded-md" aria-label="Pagination">
											<button
												type="button"
												className={cn(
													'relative inline-flex items-center rounded p-1 text-sm font-medium text-[#FFFFFF] bg-[#1232584D] focus:z-20',
													!table.getCanPreviousPage() && 'disabled text-[#C4CDD5] opacity-30'
												)}
												onClick={() => table.previousPage()}>
												<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
												<span className="sr-only">Previous</span>
											</button>

											{pageArray.map(page => (
												<button
													type="button"
													key={page}
													className={cn(
														'relative bg-white inline-flex w-8 items-center justify-center rounded border px-1 py-[5px] text-primary text-sm font-bold',
														page - 1 === table.getState().pagination.pageIndex
															? 'className="relative z-10 inline-flex items-center border-primary focus:z-20'
															: 'border-[#DFE3E8]'
													)}
													onClick={() => table.setPageIndex(page - 1)}>
													{page}
												</button>
											))}
											{!pageArray.includes(table.getPageCount()) && (
												<>
													{table.getState().pagination.pageIndex < table.getPageCount() - 4 ? (
														<div>...</div>
													) : (
														''
													)}
													<button
														type="button"
														key={'last'}
														className={cn(
															'relative bg-white inline-flex w-8 items-center justify-center rounded border px-1 py-[5px] text-primary text-sm font-bold',
															table.getPageCount() - 1 === table.getState().pagination.pageIndex
																? 'className="relative z-10 inline-flex items-center border-primary focus:z-20'
																: 'border-[#DFE3E8]'
														)}
														onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
														{table.getPageCount()}
													</button>
												</>
											)}

											<button
												type="button"
												className={cn(
													'relative inline-flex items-center rounded p-1 text-sm font-medium text-[#FFFFFF] bg-[#1232584D] focus:z-20',
													!table.getCanNextPage() && 'disabled text-[#C4CDD5] opacity-30'
												)}
												onClick={() => table.nextPage()}>
												<span className="sr-only">Next</span>
												<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
											</button>
										</nav>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	)
}
