import {
	Listbox,
	ListboxButton,
	ListboxOption,
	ListboxOptions,
	Menu,
	Popover,
	Transition
} from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import {
	ArrowLeftStartOnRectangleIcon,
	ChevronDownIcon,
	Cog6ToothIcon,
	EnvelopeIcon,
	PhoneIcon
} from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { ProfilePhotoImage } from '@/assets/icons'
import { Language } from '@/constants'
import { useAppSelector } from '@/hooks'
import authService from '@/services/auth-service'

interface MenuProps {
	options: string[]
	value: string
	onChange: (value: string) => void
	className?: string
	shouldIgnore?: boolean
}
export const LanguageMenu = ({ value, options, onChange, className }: MenuProps) => {
	const handleChange = (value: string) => {
		onChange(value)
	}

	return (
		<div className="w-14 md:w-20">
			<Listbox value={value} onChange={handleChange}>
				<div className="relative">
					<ListboxButton
						className={clsx(
							'relative bg-white flex justify-between items-center w-full cursor-pointer rounded-[100px] border border-[#D3E3F1] search-shadow pl-1.5 md:pl-3 py-1 md:py-2.5 text-center focus:outline-none focus-visible:border-main focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-main-300 sm:text-sm',
							className
						)}>
						<span className="uppercase flex shrink-0 items-center gap-x-2 truncate font-semibold text-tertiary text-md">
							{(value === Language.EN || value === 'en-US') && (
								<span className="fi fi-us rounded-full text-lg" />
							)}
							{value === Language.DE && <span className="fi fi-ch rounded-full text-lg" />}
							<span className="text-xs max-md:hidden">{value === 'en-US' ? 'EN' : value}</span>
						</span>
						<span className="absolute grow right-0 translate-x-1 md:translate-x-2 pointer-events-none flex items-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								className="-translate-x-1.5">
								<path d="M15.8077 10.6875H8.19226L12 14.4952L15.8077 10.6875Z" fill="#123258" />
							</svg>
						</span>
					</ListboxButton>

					<ListboxOptions
						anchor="bottom"
						transition
						className="absolute z-50 mt-1 max-h-60 w-max overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{options.map((option, index) => (
							<ListboxOption
								key={index}
								className="relative group data-[selected]:bg-gray-200 text-gray-200 bg-white data-[selected]:text-primary cursor-pointer z-50 select-none py-2 pl-10 font-semibold pr-4 capitalize text-md"
								value={option}>
								<span className="block truncate group-data-[selected]:font-bold font-medium">
									{index === 0 && <span className="fi fi-us rounded-full text-lg" />}
									{index === 1 && <span className="fi fi-ch rounded-full text-lg" />}
								</span>
								<span className="absolute group-data-[selected]:visible invisible inset-y-0 left-0 flex items-center pl-3 text-primary">
									<CheckIcon className="h-5 w-5" aria-hidden="true" />
								</span>
							</ListboxOption>
						))}
					</ListboxOptions>
				</div>
			</Listbox>
		</div>
	)
}

export const MenuDropdown = ({ options, onChange, value, shouldIgnore, className }: MenuProps) => {
	const { t } = useTranslation()
	return (
		<Menu as="div" className="relative rounded-xl inline-block text-left">
			{({ open }) => (
				<>
					<Menu.Button
						className={clsx(
							'flex gap-x-1 md:gap-x-2 bg-white border w-full text-primary focus:border-primary-light items-center text-sm font-semibold rounded-[6px] py-1.5 px-3 md:px-4 md:py-2.5 focus:outline-none',
							{
								'!py-1.5': shouldIgnore,
								'bg-[#13BAB40F] border-primary-light': open
							}
						)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none">
							<path
								d="M7.16254 11.6365L13.6414 5.15769C13.7529 5.04616 13.8854 4.98918 14.0387 4.98677C14.1921 4.98437 14.327 5.04134 14.4433 5.15769C14.5596 5.27403 14.6178 5.40768 14.6178 5.55864C14.6178 5.70959 14.5596 5.84324 14.4433 5.95959L7.63704 12.7658C7.50147 12.9014 7.3433 12.9692 7.16254 12.9692C6.98177 12.9692 6.8236 12.9014 6.68803 12.7658L3.55678 9.63459C3.44524 9.52306 3.39019 9.3906 3.39163 9.23724C3.39308 9.08388 3.45197 8.94903 3.56831 8.83269C3.68466 8.71634 3.81831 8.65817 3.96926 8.65817C4.12022 8.65817 4.25387 8.71634 4.37021 8.83269L7.16254 11.6365Z"
								fill="#5964D3"
							/>
						</svg>
						<span
							className={clsx(
								'whitespace-nowrap max-md:text-xs',
								open ? 'text-primary-light' : 'text-primary'
							)}>
							{shouldIgnore ? value : options[0] === value ? t(`menu.labels.${value}`) : value}
						</span>
						<ChevronDownIcon className="h-4 w-4 ml-auto text-[#12325866]" />
					</Menu.Button>
					{open && (
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Menu.Items
								style={{ width: '-webkit-fill-available' }}
								className={clsx(
									'absolute text-sm z-10 mt-1 w-full origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -left-10',
									className
								)}
								static>
								<div className="px-1 py-1">
									{options.map((option, index) => (
										<Menu.Item key={option}>
											<button
												className={clsx(
													'group text-primary text-xs font-semibold w-full md:text-sm whitespace-nowrap grow hover:bg-[#06AB910F] flex text-left items-center rounded-md px-3 pr-6 py-3'
												)}
												onClick={() => onChange(option)}>
												{shouldIgnore ? option : index === 0 ? t(`menu.labels.${option}`) : option}
											</button>
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Transition>
					)}
				</>
			)}
		</Menu>
	)
}

export const ProfileMenu = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const auth = useAppSelector(state => state.auth)

	return (
		<Menu as="div" className="relative z-50">
			<Menu.Button className="relative flex items-center gap-x-2">
				<img
					src={auth.profile_url === 'crm' ? ProfilePhotoImage : auth.profile_url}
					className="h-[30px] w-[30px] md:h-10 md:w-10 object-cover rounded-full"
				/>
				<div className="flex flex-col gap-y-1 max-md:hidden">
					<h3 className="text-tertiary font-sm font-semibold">{auth.fname + ' ' + auth.lname}</h3>
					<p
						className={clsx(
							'rounded-full text-xs font-semibold text-[#FFF] px-1.5',
							auth.roles.includes('ROLE_SUPERADMIN')
								? 'bg-purple-500'
								: auth.is_adminUser === true
									? 'bg-[#F5B715CC]'
									: 'bg-[#13BAB4]'
						)}>
						{auth.roles.includes('ROLE_SUPERADMIN')
							? t('menu.labels.superAdmin')
							: auth.is_adminUser === true
								? t('menu.labels.administrator')
								: t('menu.labels.employee')}
					</p>
				</div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					className="max-md:hidden"
					fill="none">
					<path d="M15.8078 10.6875H8.19238L12.0001 14.4952L15.8078 10.6875Z" fill="#123258" />
				</svg>
			</Menu.Button>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-200"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95">
				<Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<Menu.Item>
						<span className="bg-white text-tertiary items-center px-2 py-1 text-xs flex gap-x-2.5">
							<EnvelopeIcon className="h-3.5 w-3.5 shrink-0" />
							{auth.email}
						</span>
					</Menu.Item>
					<Menu.Item>
						<span className="bg-white text-tertiary items-center px-2 pt-1 pb-2 text-xs flex gap-x-2.5">
							<PhoneIcon className="h-3.5 w-3.5 shrink-0" />
							{auth.phone}
						</span>
					</Menu.Item>
					<Menu.Item>
						{({ active }) => (
							<span
								onClick={() => navigate('/settings')}
								className={clsx(
									active ? 'bg-primary-light text-white' : 'bg-white text-tertiary',
									'cursor-pointer px-2 py-2 border-t border-border items-center text-sm flex gap-x-2.5 whitespace-nowrap'
								)}>
								<Cog6ToothIcon className="h-4 w-4 shrink-0" />
								{t('menu.labels.settings')}
							</span>
						)}
					</Menu.Item>
					<Menu.Item>
						{({ active }) => (
							<span
								onClick={() => authService.logout()}
								className={clsx(
									active ? 'bg-primary-light text-white' : 'bg-white text-tertiary',
									'cursor-pointer px-2 py-2 border-t border-border items-center text-sm flex gap-x-2.5 whitespace-nowrap'
								)}>
								<ArrowLeftStartOnRectangleIcon className="h-4 w-4 shrink-0" />
								Logout
							</span>
						)}
					</Menu.Item>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

interface MenuOptions {
	options: {
		icon: React.ElementType
		color: string
		action: () => void
	}[]
	orientation?: 'vertical' | 'horizontal'
}

export const EllipsisMenu = ({ options, orientation = 'vertical' }: MenuOptions) => {
	return (
		<Menu as="div" className="relative inline-block text-left rounded-lg">
			{({ open }) => (
				<>
					<Menu.Button
						onClick={event => event.stopPropagation()}
						className="inline-flex w-full cursor-pointer items-center py-2 md:py-5 pl-2.5 pr-0 text-slate-900">
						<div
							className={clsx(
								'flex gap-[3px] items-center justify-center',
								orientation === 'horizontal' ? 'flex-col' : 'flex-row'
							)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="4"
								height="4"
								viewBox="0 0 4 4"
								fill="none">
								<path
									d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
									fill="#7F9AB2"
								/>
							</svg>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="4"
								height="4"
								viewBox="0 0 4 4"
								fill="none">
								<path
									d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
									fill="#7F9AB2"
								/>
							</svg>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="4"
								height="4"
								viewBox="0 0 4 4"
								fill="none">
								<path
									d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
									fill="#7F9AB2"
								/>
							</svg>
						</div>
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Menu.Items
							style={{ boxShadow: '0px 4px 24px 0px rgba(127, 154, 178, 0.25)' }}
							className={clsx(
								'absolute border border-border flex origin-top-left top-0 left-0 -translate-y-6 scale-100 transform rounded-lg bg-white text-sm font-normal text-primary opacity-100 focus:outline-none',
								{
									'-translate-x-1.5': options.length === 1 && orientation === 'vertical',
									'-translate-x-10': options.length === 2 && orientation === 'vertical',
									'-translate-x-20': options.length === 3 && orientation === 'vertical',
									'-translate-x-11': orientation === 'horizontal'
								}
							)}>
							<div
								onClick={event => event.stopPropagation()}
								className="truncate flex divide-x divide-[#EEEEEE]"
								role="none">
								{options.map((option, index) => (
									<Menu.Item key={index}>
										<button
											onClick={option.action}
											className="group flex w-full items-center rounded-lg p-2 text-sm hover:bg-gray-100">
											<option.icon className={clsx('w-5 h-5', option.color)} />
										</button>
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	)
}

interface MenuColorPickerProps {
	color: string
	children: React.ReactNode
	setColor: (value: string) => void
}

export const MenuColorPicker = ({ color, setColor, children }: MenuColorPickerProps) => {
	return (
		<Popover as="div" className="flex flex-col gap-y-[3px]">
			{({ open }) => (
				<>
					<Popover.Button className="cursor-pointer relative w-full">{children}</Popover.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Popover.Panel
							style={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.10)' }}
							className="w-full color-picker rounded-lg focus:outline-none">
							<HexColorPicker color={color} onChange={setColor} />
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	)
}
