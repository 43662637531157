import { Combobox, Transition } from '@headlessui/react'
import { ArrowPathIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import generator from 'generate-password-browser'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { Input } from '@/components/inputs/input'
import { RadioInput } from '@/components/inputs/radio'
import { Select } from '@/components/inputs/select'
import { Spinner } from '@/components/spinner'
import { Language } from '@/constants'
import { UserForm } from '@/pages/clients/create'
import employeeService from '@/services/employee-service'
import { cn } from '@/utils/cn'
import { getAppLang, getTKey } from '@/utils/language'

interface CreateEmployeeProps {
	onCreation: () => void
	onCancel: () => void
	companies: Company[]
	employeeId: string | null
	isCalendarForm?: boolean
	className?: string
	setCreatedClient?: (id: string) => void
}

export const CreateEmployee = ({
	onCreation,
	onCancel,
	isCalendarForm,
	employeeId,
	companies,
	className,
	setCreatedClient
}: CreateEmployeeProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('employees.create')

	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [query, setQuery] = useState('')

	const schema = yup.object<UserForm>().shape({
		fname: yup.string().required(t(tKey('errors.firstName'))),
		lname: yup.string().required(t(tKey('errors.lastName'))),
		gender: yup.string().required(t(tKey('errors.gender'))),
		password: yup
			.string()
			.required(t(tKey('errors.password')))
			.matches(
				/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
				t('employees.create.errors.validPassword')
			)
			.min(8, t(tKey('errors.minPassword'))),
		email: yup.string().notRequired(),
		companyId: yup.string().required(t(tKey('errors.company'))),
		biography: yup.string().required(t(tKey('errors.biography'))),
		startingDate: yup
			.date()
			.required(t(tKey('errors.startDate')))
			.typeError(t(tKey('errors.startDate'))),
		expiryDate: yup
			.date()
			.required(t(tKey('errors.expiryDate')))
			.typeError(t(tKey('errors.expiryDate')))
			.test('is-greater', t(tKey('errors.minExpiryDate')), function (value) {
				const { startingDate } = this.parent
				return value > startingDate
			}),
		phone: yup
			.string()
			.required(t(tKey('errors.phone')))
			.min(9, t(tKey('errors.minPhone')))
			.max(13, t(tKey('errors.maxPhone'))),
		birthDate: yup.string().notRequired(),
		address_no: yup.string().notRequired(),
		country: yup.string().required(t(tKey('errors.country'))),
		address: yup.string().required(t(tKey('errors.address'))),
		city: yup.string().required(t(tKey('errors.city'))),
		is_active: yup.string().required(t(tKey('errors.isActive'))),
		zip: yup
			.number()
			.typeError(t(tKey('errors.validZip')))
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.required(t(tKey('errors.zip')))
	})

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors },
		control
	} = useForm<UserForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			phone: '+41',
			country: getAppLang() === Language.EN ? 'Switzerland' : 'Schweiz',
			password: generator.generate({
				length: 10,
				numbers: true,
				uppercase: true,
				strict: true
			})
		},
		mode: 'all'
	})

	useEffect(() => {
		if (employeeId) {
			employeeService.getEmployeeById(employeeId).then(res =>
				reset({
					fname: res.fname,
					lname: res.lname,
					email: res.email,
					password: generator.generate({
						length: 10,
						numbers: true,
						uppercase: true,
						strict: true
					}),
					startingDate: DateTime.fromMillis(Number(res.startingDate)).toFormat('yyyy-LL-dd') as any,
					expiryDate: DateTime.fromMillis(Number(res.expiryDate)).toFormat('yyyy-LL-dd') as any,
					gender: res.gender,
					companyId: res.companyId,
					country: getAppLang() === Language.EN ? 'Switzerland' : 'Schweiz',
					biography: res.biography,
					birthDate: DateTime.fromMillis(Number(res.birthDate)).toFormat('yyyy-LL-dd') as any,
					phone: res.phone,
					address: res.address,
					address_no: res.address_no,
					is_active: res.is_active,
					zip: res.zip,
					city: res.city
				})
			)
		}
	}, [employeeId])

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const { password, ...rest } = data

		const selectedCompany = companies.find(company => company._id === data.companyId)

		if (employeeId) {
			const payload = {
				...rest,
				birthDate: data.birthDate
					? DateTime.fromFormat(data.birthDate, 'yyyy-LL-dd').toMillis()
					: null,
				startingDate: DateTime.fromJSDate(data.startingDate).toMillis(),
				expiryDate: DateTime.fromJSDate(data.expiryDate).toMillis(),
				created: DateTime.now().toMillis()
			}
			employeeService
				.updateEmployee(employeeId, payload)
				.then(res => {
					toast.success(t(tKey('toast.updateSuccess')))
					setCreatedClient?.(res._id)
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.updateError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			const payload: UserForm = {
				...data,
				roles: selectedCompany?.is_super_company
					? ['user', 'employee', 'admin', 'moderator', 'superadmin']
					: ['user', 'employee', 'admin', 'moderator'],
				birthDate: data.birthDate
					? DateTime.fromFormat(data.birthDate, 'yyyy-LL-dd').toMillis()
					: null,
				startingDate: DateTime.fromJSDate(data.startingDate).toMillis(),
				expiryDate: DateTime.fromJSDate(data.expiryDate).toMillis(),
				created: DateTime.now().toMillis()
			}
			employeeService
				.createEmployee(payload)
				.then(res => {
					toast.success(t(tKey('toast.employeeSuccess')))
					setCreatedClient?.(res._id)
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.employeeError')))
				})
				.finally(() => setIsLoading(false))
		}
	}

	const filteredOptions = useMemo(() => {
		if (companies) {
			return companies.filter(company => {
				return (
					query === '' ||
					company.company_name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return companies
		}
	}, [companies, query])

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={clsx('md:px-8 py-6 max-md:px-5', className)}>
			<div className="flex flex-col gap-y-5">
				<div className="flex justify-between items-center">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.basicInfo'))}
					</h2>
					{!employeeId && (
						<p
							onClick={() =>
								setValue(
									'password',
									generator.generate({
										length: 10,
										numbers: true,
										uppercase: true,
										strict: true
									})
								)
							}
							className="text-primary-light whitespace-nowrap hover:underline flex items-center gap-x-1 cursor-pointer text-sm">
							<ArrowPathIcon className="size-4" />
							{t('employees.create.labels.regeneratePassword')}
						</p>
					)}
				</div>

				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Controller
						control={control}
						name="gender"
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-col gap-y-1">
								<h3 className="text-primary text-sm">{t(tKey('labels.gender'))}</h3>
								<div className="flex flex-row gap-x-3">
									<RadioInput
										labelText={t(tKey('labels.male'))}
										onChange={onChange}
										name="gender"
										register={register}
										value="male"
										checked={value === 'male'}
									/>
									<RadioInput
										labelText={t(tKey('labels.female'))}
										onChange={onChange}
										register={register}
										name="gender"
										value="female"
										checked={value === 'female'}
									/>
								</div>
								{errors?.gender && (
									<p className="text-xs text-red-500 mt-1">{errors.gender.message as string}</p>
								)}
							</div>
						)}
					/>

					<Combobox
						value={getValues('companyId')}
						onChange={(value: string) => setValue('companyId', value, { shouldValidate: true })}>
						<div className="relative mt-1">
							<div className="relative cursor-default w-full bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="companyId"
										className="absolute -top-2.5 z-10 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.company'))}
									</label>
									<Combobox.Input
										placeholder={t(tKey('labels.selectCompany'))}
										className="border border-border pr-7 w-full py-2 md:py-3 rounded focus:border-primary-light text-sm leading-5 text-gray-900 focus:ring-0"
										displayValue={option =>
											option
												? (companies.find(company => company._id === getValues('companyId'))
														?.company_name as string)
												: ''
										}
										onChange={event => setQuery(event.target.value)}
									/>
									{errors.companyId && (
										<p className="text-red-500 mt-1 text-xs">
											{errors.companyId.message as string}
										</p>
									)}
								</div>

								<Combobox.Button
									className={clsx('absolute inset-y-0 flex items-center right-0 pr-2', {
										'-translate-y-2': errors.companyId
									})}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none">
										<path
											d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
											fill="#123258"
										/>
									</svg>
								</Combobox.Button>
							</div>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0">
								<Combobox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
									{Array.isArray(companies) &&
										filteredOptions.map((option, index) => (
											<Combobox.Option
												key={index}
												className={({ active }) =>
													`relative cursor-default select-none py-0.5 ${
														active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
													}`
												}
												value={option._id}>
												{({ selected, active }) => (
													<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
														<span className="flex-col group items-start text-primary w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow flex text-left rounded-md">
															{option.company_name}
														</span>
													</span>
												)}
											</Combobox.Option>
										))}
								</Combobox.Options>
							</Transition>
						</div>
					</Combobox>
				</div>

				<div
					className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
						'!flex-col': isCalendarForm
					})}>
					<Input
						register={register}
						errors={errors}
						name="fname"
						labelText={t(tKey('labels.firstName'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="lname"
						labelText={t(tKey('labels.lastName'))}
					/>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input register={register} errors={errors} name="email" labelText="Email" />
					<div>
						<div className="relative z-0 flex items-center">
							<Input
								type={isPasswordVisible ? 'text' : 'password'}
								register={register}
								autoCapitalize="false"
								autoCorrect="off"
								autoComplete="new-password"
								disabled={!!employeeId}
								name="password"
								className={cn({ 'bg-gray-100': !!employeeId })}
								labelText={t(tKey('labels.password'))}
							/>
							<div
								onClick={() => setIsPasswordVisible(!isPasswordVisible)}
								className="absolute right-4">
								{isPasswordVisible ? (
									<EyeIcon
										className={cn('w-6 h-6', employeeId ? 'text-gray-400' : 'text-primary')}
									/>
								) : (
									<EyeSlashIcon
										className={cn('w-6 h-6', employeeId ? 'text-gray-400' : 'text-primary')}
									/>
								)}
							</div>
						</div>
						{errors.password && (
							<p className="text-xs text-red-500 mt-1">{errors.password.message as string}</p>
						)}
					</div>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input
						name="birthDate"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.dob'))}
						type="date"
					/>
					<Controller
						control={control}
						name={'phone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.phone'))}
									</label>
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										placeholder="Enter phone number"
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
								</div>
								{errors?.phone && (
									<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
								)}
							</div>
						)}
					/>
				</div>

				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input
						name="startingDate"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.startDate'))}
						type="date"
					/>
					<Input
						name="expiryDate"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.expiryDate'))}
						type="date"
					/>
				</div>

				<div className="flex flex-col w-full">
					<div className="relative overflow-visible rounded-md">
						<label
							htmlFor="name"
							className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
							{t(tKey('labels.biography'))}
						</label>
						<textarea
							{...register('biography')}
							placeholder={t(tKey('labels.cv'))}
							rows={4}
							className="w-full disabled:text-gray-500 rounded text-sm placeholder:text-sm font-normal pl-4 py-3 bg-transparent focus:ring-0 border focus:border-primary-light border-[#D3E3F1] text-primary placeholder-[#7F9AB2] focus:outline-none max-md:text-sm"
						/>
					</div>
					{errors.biography && (
						<p className="text-xs text-red-500 mt-1">{errors.biography.message}</p>
					)}
				</div>

				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.locationInfo'))}
					</h2>
					<div className="flex flex-col gap-y-5">
						<div
							className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
								'!flex-col': isCalendarForm
							})}>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.location'))}
								name="address"
							/>
							<Input
								register={register}
								errors={errors}
								inputMode="numeric"
								type="number"
								min="0"
								labelText={t(tKey('labels.addressNo'))}
								name="address_no"
							/>
							<Input
								register={register}
								errors={errors}
								type="number"
								inputMode="numeric"
								min="0"
								labelText={t(tKey('labels.zipCode'))}
								name="zip"
							/>
						</div>
						<div
							className={clsx(
								'grid grid-cols-1 items-start md:grid-cols-3 gap-y-5 md:grid-flow-col gap-x-5',
								{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
							)}>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.city'))}
								name="city"
							/>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.country'))}
								name="country"
							/>
							<Select
								labelText={t(tKey('labels.status'))}
								name="is_active"
								register={register}
								errors={errors}>
								<option value="">{t(tKey('labels.status'))}</option>
								<option value="aktiv">{t(tKey('labels.active'))}</option>
								<option value="inaktiv">{t(tKey('labels.inactive'))}</option>
							</Select>
						</div>
					</div>

					<div className="flex gap-x-6 justify-end">
						<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
							{t(tKey('buttons.cancel'))}
						</button>
						<Button disabled={isLoading} className="text-sm font-bold">
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('buttons.pleaseWait'))}
									</span>
								</div>
							) : (
								<span>{employeeId ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</form>
	)
}
