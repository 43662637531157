import { UserForm } from '@/pages/clients/create'
import axios from '@/services/axios'

const getAllUsers = (): Promise<User[]> => {
	return axios.get('auth/company-users').then(response => response.data)
}

const getUserById = (id: string): Promise<User> => {
	return axios.get(`/auth/user/${id}`).then(response => response.data)
}

const createUser = (data: UserForm) => {
	return axios.post('/auth/signup', data).then(response => {
		return response.data
	})
}

const updateUser = (id: string, data: UserForm) => {
	return axios.put(`/auth/user/${id}`, data).then(response => {
		return response.data
	})
}

const updateUserProfile = (formData: FormData) => {
	return axios
		.post('/user/profile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateUserPassword = (old_password: string, new_password: string) => {
	return axios
		.put('/auth/changepassword', { old_password, new_password })
		.then(response => response.data)
}

const sendRegistrationEmail = (data: {
	name: string
	phone: string
	email: string
	birthDate: string
	password: string
}) => {
	return axios.post('/auth/client/sendUserData', data).then(res => res.data)
}

const userService = {
	getAllUsers,
	getUserById,
	updateUser,
	updateUserProfile,
	sendRegistrationEmail,
	updateUserPassword,
	createUser
}

export default userService
