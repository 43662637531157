import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import App from '@/app'
import '@/i18n'
import store from '@/store'
import 'react-quill/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.css'
import '/node_modules/flag-icons/css/flag-icons.min.css'

const container = document.getElementById('root')

const persistor = persistStore(store)

if (container) {
	const root = createRoot(container)
	root.render(
		<StrictMode>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ToastContainer limit={3} newestOnTop={true} pauseOnFocusLoss={false} />
					<App />
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}
