import authService from '@/services/auth-service'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export const login = createAsyncThunk(
	'auth/login',
	async (user: { username: string; otp: string }, thunkAPI) => {
		try {
			const response = await authService.userVerification(user.username, user.otp)
			if (response.accessToken) {
				return thunkAPI.fulfillWithValue({ ...response })
			}
			return thunkAPI.rejectWithValue(false)
		} catch (error: any) {
			return thunkAPI.rejectWithValue(false)
		}
	}
)

const initialState = {} as AuthState

const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		updateUserProfile(state, action: PayloadAction<AuthState['profile_url']>) {
			return { ...state, profile_url: action.payload }
		},
		logOut(state) {
			return initialState
		}
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(login.fulfilled, (state: AuthState, action: PayloadAction<AuthState>) => {
			const user = action.payload
			return user
		}),
			builder.addCase(login.rejected, (state: AuthState, action: PayloadAction<any>) => {
				return initialState
			})
	}
})

const { reducer } = authSlice

export const { updateUserProfile, logOut } = authSlice.actions

export default reducer
