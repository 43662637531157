import { useTranslation } from 'react-i18next'

import { DangerIcon } from '@/assets/icons'
import { Modal } from '@/components/modal'
import useIsMobile from '@/hooks/useIsMobile'
import { getTKey } from '@/utils/language'

interface DeletePromptProps {
	heading: string
	message: string
	onClose: () => void
	onDelete: () => void
}

export const DeletePrompt = ({ heading, message, onClose, onDelete }: DeletePromptProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()

	const tKey = getTKey('deletePrompt')

	return (
		<Modal width={isMobile ? 'w-[344px]' : 'w-[512px]'} showCrossIcon={false}>
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col max-md:items-center md:flex-row gap-x-4">
					<img src={DangerIcon} className="shrink-0 w-[40px] h-[40px]" />
					<div className="flex max-md:items-center flex-col gap-y-2">
						<h2 className="font-domine text-lg text-primary">{heading}</h2>
						<p className="text-tertiary max-md:text-center text-sm">{message}</p>
					</div>
				</div>
				<div className="flex flex-col md:flex-row md:justify-end md:items-center max-md:gap-y-5 gap-x-3">
					<button
						onClick={onClose}
						className="button-shadow border border-[#D1D5DB] rounded-md px-[17px] py-[9px] text-sm text-[#7F9AB2] hover:text-black hover:border-black">
						{t(tKey('buttons.cancel'))}
					</button>
					<button
						onClick={onDelete}
						className="button-shadow rounded-md px-[17px] py-[9px] bg-[#DC2626] hover:bg-red-700 text-white">
						{t(tKey('buttons.delete'))}
					</button>
				</div>
			</div>
		</Modal>
	)
}
