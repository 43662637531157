import { UserForm } from '@/pages/clients/create'
import axios from '@/services/axios'

const getAllEmployees = (): Promise<User[]> => {
	return axios.get('auth/company-employees').then(response => response.data)
}

const getEmployeeById = (id: string): Promise<User> => {
	return axios.get(`/auth/user/${id}`).then(response => response.data)
}

const createEmployee = (data: UserForm) => {
	return axios.post('/auth/employee/signup', data).then(response => {
		return response.data
	})
}

const updateEmployee = (id: string, data: UserForm) => {
	return axios.put(`/auth/user/${id}`, data).then(response => {
		return response.data
	})
}

const employeeService = {
	getAllEmployees,
	getEmployeeById,
	updateEmployee,
	createEmployee
}

export default employeeService
