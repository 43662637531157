import axios from '@/services/axios'

const getArticles = (): Promise<Article[]> => {
	return axios.get('/articles').then(response => response.data)
}

const getArticleById = (articleId: string): Promise<Article> => {
	return axios.get(`/article/${articleId}`).then(response => response.data)
}

const createArticle = (data: FormData) => {
	return axios
		.post('/article', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateArticle = (articleId: string, data: FormData) => {
	return axios
		.put(`/article/${articleId}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const deleteArticle = (articleId: string) => {
	return axios.delete(`/article/${articleId}`).then(response => response.data)
}

const articleService = {
	getArticles,
	getArticleById,
	createArticle,
	updateArticle,
	deleteArticle
}

export default articleService
