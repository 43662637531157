import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import ReactQuill from 'react-quill'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { MultiCombobox } from '@/components/combo-selector'
import { ImageUpload } from '@/components/image-upload'
import { Input } from '@/components/inputs/input'
import { RadioInput } from '@/components/inputs/radio'
import { Select } from '@/components/inputs/select'
import { AppLayout } from '@/components/layout'
import { Spinner } from '@/components/spinner'
import { Tabs } from '@/components/tabs'
import { Language, germanToEnglishDays } from '@/constants'
import { useAppSelector } from '@/hooks'
import useIsMobile from '@/hooks/useIsMobile'
import companyService from '@/services/company-service'
import companyTypeService from '@/services/company-type-service'
import { cn } from '@/utils/cn'
import { getLocalizedWeekDays } from '@/utils/date'
import { getAppLang, getTKey } from '@/utils/language'

enum ProfileTabs {
	COMPANYDETAILS = 'companyDetails',
	GOOGLEMAPS = 'googleMaps',
	SOCIALMEDIA = 'socialMedia',
	WORKTIME = 'workTime'
}

export const EditCompany = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companies.create')

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.profile'))}>
			<div className="md:pt-6 pt-5">
				<h1 className="text-primary font-domine font-bold md:hidden pb-5 px-5">
					{t(tKey('titles.profile'))}
				</h1>
				<div className="border-b border-[#E5E7EB] overflow-x-auto block pb-px px-5 max-md:mr-4 md:px-6">
					<Tabs
						tabs={Object.values(ProfileTabs)}
						setTab={(value: string) => setSearchParams({ profile: value }, { replace: true })}
						selectedTab={searchParams.get('profile') ?? ProfileTabs.COMPANYDETAILS}
					/>
				</div>

				{(searchParams.get('profile') === ProfileTabs.COMPANYDETAILS ||
					searchParams.get('profile') === null) && <CompanyDetails />}
				{searchParams.get('profile') === ProfileTabs.GOOGLEMAPS && <GoogleMaps />}
				{searchParams.get('profile') === ProfileTabs.SOCIALMEDIA && <SocialMedia />}
				{searchParams.get('profile') === ProfileTabs.WORKTIME && <WorkTime />}
			</div>
		</AppLayout>
	)
}

interface CompanyDetailForm {
	about_company: string
	agb: string
	about_us: string
	is_super_company: string
	isCompanyRegistered: string
	subCompaniesAllowed?: number
	transportation: string
	phone: string
	fax: string
	email: string
	website: string
	konkordac: string
	gln_number: string
	address: string
	address_no: string
	country: string
	uID?: string
	city: string
	zip: string
	marketing?: string
	company_type: string[]
	logo?: any
	slider?: any
}

const CompanyDetails = () => {
	const { id: companyId } = useParams() as { id: string }
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companies.create')

	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [categories, setCategories] = useState<CompanyType[]>([])
	const [selectedCategories, setSelectedCategories] = useState<string[]>([])
	const [logo, setLogo] = useState<File>()
	const [coverPhoto, setCoverPhoto] = useState<File>()
	const [isLoading, setIsLoading] = useState(false)
	const [companySlider, setCompanySlider] = useState<string>()
	const [companyLogo, setCompanyLogo] = useState<string>()

	const schema = yup.object<CompanyDetailForm>().shape({
		company_name: yup.string().required(t(tKey('errors.company_name'))),
		email: yup
			.string()
			.notRequired()
			.email(t(tKey('errors.validEmail'))),
		phone: yup
			.string()
			.notRequired()
			.min(9, t(tKey('errors.minPhone')))
			.max(13, t(tKey('errors.maxPhone'))),
		company_type: yup
			.array()
			.test('company_type-test', t(tKey('errors.category')), function (value) {
				if (selectedCategories.length === 0) return false
				return true
			}),
		about_company: yup
			.string()
			.test('about_company-test', t(tKey('errors.aboutCompany')), function (value) {
				if (!value) return false
				if (value === '<p><br></p>') return false
				return true
			}),
		agb: yup.string().test('agb-test', t(tKey('errors.agb')), function (value) {
			return true
		}),
		about_us: yup.string().notRequired(),
		transportation: yup.string().notRequired(),
		marketing: yup.string().required(t(tKey('errors.allowAppointments'))),
		is_super_company: yup.string().required(t(tKey('errors.isSuperCompany'))),
		isCompanyRegistered: yup.string().required(t(tKey('errors.isCompanyRegistered'))),
		uID: yup.string().test('uID-validation', t(tKey('errors.uID')), function (value) {
			if (this.parent.isCompanyRegistered !== 'true') return true
			if (!value) return false
			const uidPattern = /^CHE-\d{3}\.\d{3}\.\d{3}$/
			if (!uidPattern.test(value)) {
				return this.createError({
					path: 'uID',
					message: t(tKey('errors.validuid'))
				})
			}
			return true
		}),
		subCompaniesAllowed: yup
			.number()
			.nullable()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.test('sub-companies-validation', t(tKey('errors.subCompanies')), function (value) {
				if (this.parent.is_super_company !== 'true') return true
				if (!value) return false
				if (value <= 0) {
					return this.createError({
						path: 'subCompaniesAllowed',
						message: t(tKey('errors.minSubCompanies'))
					})
				}
				if (typeof value !== 'number') {
					return this.createError({
						path: 'subCompaniesAllowed',
						message: t(tKey('errors.validSubCompanies'))
					})
				}
				return true
			}),
		website: yup.string().required(t(tKey('errors.website'))),
		konkordac: yup.string().notRequired(),
		fax: yup.string().notRequired(),
		address: yup.string().required(t(tKey('errors.address'))),
		address_no: yup.string().notRequired(),
		zip: yup.string().required(t(tKey('errors.zip'))),
		google_review_link: yup.string().required(t(tKey('errors.googleReview'))),
		city: yup.string().required(t(tKey('errors.city'))),
		country: yup.string().required(t(tKey('errors.country'))),
		gln_number: yup.string().notRequired()
	})

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		watch,
		clearErrors,
		formState: { errors },
		control
	} = useForm<CompanyDetailForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const isSuperCompany = watch('is_super_company')
	const isCompanyRegistered = watch('isCompanyRegistered')

	useEffect(() => {
		setValue('subCompaniesAllowed', undefined)
	}, [isSuperCompany])

	useEffect(() => {
		companyTypeService.getCompanyTypes().then(res => {
			setCategories(res)
		})
		if (companyId) {
			companyService.getCompanyById(companyId).then(res => {
				companyService.getCompanyLogo(res._id).then(res => setCompanyLogo(res[0].url))
				companyService.getCompanySlider(res._id).then(res => setCompanySlider(res[0].url))
				setSelectedCategories(res.company_type)
				reset({
					...res,
					isCompanyRegistered: res.isCompanyRegistered ? 'true' : 'false',
					is_super_company: res.is_super_company ? 'true' : 'false'
				})
			})
		}
	}, [])

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const formData = new FormData()
		const { parent_company, subCompaniesAllowed, ...rest } = data
		const payload = subCompaniesAllowed ? { subCompaniesAllowed, ...rest } : rest
		for (const key in payload) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				if (key === 'company_type') continue
				const value = (data as { [key: string]: any })[key]
				formData.append(key, value)
			}
		}
		for (const category of selectedCategories) {
			formData.append('company_type[]', category)
		}
		if (logo) {
			formData.append('logo', logo)
		}
		if (coverPhoto) {
			formData.append('slider', coverPhoto)
		}
		formData.delete('gallery')
		formData.delete('blocked_clients')

		companyService
			.updateCompany(companyId, formData, !!coverPhoto)
			.then(() => {
				toast.success(t(tKey('toast.updateSuccess')))
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error?.response?.data?.message)
				}
				toast.error(t(tKey('toast.updateError')))
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col max-md:mb-[81px] mb-24 gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.companyDetails'))}
					</h1>
					<p className="text-sm">{t(tKey('labels.message'))}</p>
					<div className="relative flex gap-x-8 w-full">
						{isMobile && (
							<div className="md:hidden h-[120px] w-[110px] absolute translate-y-20 translate-x-3 z-20">
								<div className="flex flex-col gap-y-3 items-center">
									<ImageUpload
										name="companyLogo"
										image={companyLogo}
										onUpload={(image: File) => setLogo(image)}
										variant="logo"
									/>
									<p className="text-tertiary text-xs">400 by 400px</p>
								</div>
							</div>
						)}
						<div className="max-md:hidden h-[120px] shrink-0 w-[155px]">
							<div className="flex flex-col gap-y-2 items-start">
								<ImageUpload
									image={companyLogo}
									name="companyLogo"
									onUpload={(image: File) => setLogo(image)}
									variant="logo"
								/>
								<p className="text-tertiary text-sm">400 by 400 pixels</p>
							</div>
						</div>
						<div className="flex flex-col gap-y-2 w-full items-center md:items-start">
							<ImageUpload
								name="companyCoverPhoto"
								image={companySlider}
								onUpload={(image: File) => setCoverPhoto(image)}
								variant="cover"
							/>
							<p className="text-tertiary ml-8 text-xs md:text-sm">1440 by 200 pixels</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-y-5">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input
							labelText={t(tKey('labels.companyName'))}
							register={register}
							errors={errors}
							name="company_name"
						/>
						<div className="flex relative flex-col gap-y-1">
							<MultiCombobox
								placeholder={t(tKey('labels.categories'))}
								options={categories.map(category => ({
									label: category.label,
									id: category.label
								}))}
								selectedItems={categories
									.filter(category => selectedCategories.includes(category.label))
									.map(category => ({
										label: category.label,
										id: category.label
									}))}
								onChange={(selectedOptions: { label: string; id: any }[]) => {
									clearErrors('company_type')
									const labelCountMap = new Map()
									selectedOptions.forEach(item => {
										const count = labelCountMap.get(item.label) || 0
										labelCountMap.set(item.label, count + 1)
									})
									const uniqueValue = selectedOptions.filter(
										item => labelCountMap.get(item.label) === 1
									)

									setSelectedCategories(uniqueValue.map(val => val.label))
								}}
								className="absolute mt-[50px] w-full"
							/>
							{errors?.company_type && (
								<p className="text-xs text-red-500">{errors.company_type?.message}</p>
							)}
						</div>
					</div>

					<div className={cn('grid grid-cols-1 gap-y-5 gap-x-5 md:grid-cols-2')}>
						<Select
							name="isCompanyRegistered"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.isCompanyRegistered'))}>
							<option value="">{t(tKey('labels.isCompanyRegistered'))}</option>
							<option value="true">{t(tKey('labels.yes'))}</option>
							<option value="false">{t(tKey('labels.no'))}</option>
						</Select>

						{isCompanyRegistered === 'true' && (
							<Input
								name="uID"
								labelText={t(tKey('labels.uID'))}
								register={register}
								errors={errors}
							/>
						)}

						<Select
							name="is_super_company"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.isSuperCompany'))}>
							<option value="">{t(tKey('labels.isSuperCompany'))}</option>
							<option value="true">{t(tKey('labels.yes'))}</option>
							<option value="false">{t(tKey('labels.no'))}</option>
						</Select>

						{isSuperCompany === 'true' && (
							<Input
								name="subCompaniesAllowed"
								type="number"
								labelText={t(tKey('labels.subCompaniesAllowed'))}
								register={register}
								errors={errors}
							/>
						)}
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="about_company"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="about_company"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.aboutCompany'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.about_company && (
							<p className="text-xs text-red-500">{errors.about_company?.message}</p>
						)}
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="about_us"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="about_us"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.aboutUs'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="transportation"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="transportation"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.nearestLandmarks'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
					</div>

					<div className="flex w-full flex-col gap-y-1">
						<Controller
							control={control}
							name="agb"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="agb"
										className="absolute z-10 font-bold -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.agb'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.agb && <p className="text-xs text-red-500">{errors.agb?.message}</p>}
					</div>

					<div className="flex flex-col gap-y-1">
						<label htmlFor="marketing" className="text-primary text-sm">
							{t(tKey('labels.allowAppointments'))}
						</label>
						<Controller
							control={control}
							name="marketing"
							render={({ field: { onChange, value } }) => (
								<div className="flex flex-col">
									<div className="flex flex-row gap-x-5">
										<RadioInput
											labelText={t(tKey('labels.yes'))}
											onChange={onChange}
											name="marketing"
											register={register}
											value="1"
											checked={value === '1'}
										/>
										<RadioInput
											labelText={t(tKey('labels.no'))}
											onChange={onChange}
											register={register}
											name="marketing"
											value="0"
											checked={value === '0'}
										/>
									</div>
									{errors?.marketing && (
										<p className="text-xs text-red-500 mt-1">
											{errors.marketing.message as string}
										</p>
									)}
								</div>
							)}
						/>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input register={register} errors={errors} name="email" labelText="Email" />
						<Controller
							control={control}
							name="phone"
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<div className="flex flex-col">
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										placeholder="Enter phone number"
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
									{errors?.phone && (
										<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
									)}
								</div>
							)}
						/>
					</div>
					<div className="grid md:grid-cols-2 grid-cols-1 max-md:gap-y-5 md:gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="website"
							labelText={t(tKey('labels.website'))}
						/>
						<Select
							name="country"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.country'))}>
							<option value="ch">{t(tKey('labels.switzerland'))}</option>
							<option value="de">{t(tKey('labels.deutschland'))}</option>
							<option value="at">{t(tKey('labels.osterreich'))}</option>
						</Select>

						<div className="hidden">
							<Input
								register={register}
								errors={errors}
								name="konkordac"
								labelText={t(tKey('labels.konkordac'))}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="address"
							labelText={t(tKey('labels.address'))}
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.addressNo'))}
							name="address_no"
						/>
					</div>

					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.city'))}
							name="city"
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.zipCode'))}
							name="zip"
						/>
					</div>

					<div className="grid md:grid-cols-2 grid-cols-1 max-md:gap-y-5 md:gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="google_review_link"
							labelText={t(tKey('labels.googleReview'))}
						/>
						<Input
							register={register}
							errors={errors}
							name="fax"
							labelText={t(tKey('labels.fax'))}
						/>
					</div>

					<div className="hidden flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="gln_number"
							labelText={t(tKey('labels.gln'))}
						/>
					</div>
				</div>
			</div>
			<div
				className={cn(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.GOOGLEMAPS })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</form>
	)
}

const GoogleMaps = () => {
	const { id: companyId } = useParams() as { id: string }
	const { t } = useTranslation()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companies.create')

	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup
		.object<{ google_iframe: string; google_map: string; longitude?: number; latitude?: number }>()
		.shape({
			google_iframe: yup.string().required(t(tKey('errors.googleIframe'))),
			google_map: yup.string().required(t(tKey('errors.googleMap'))),
			longitude: yup
				.number()
				.typeError(t(tKey('errors.validLongitude')))
				.transform((value, originalValue) => {
					return originalValue === '' ? undefined : value
				})
				.notRequired(),
			latitude: yup
				.number()
				.typeError(t(tKey('errors.validLatitude')))
				.transform((value, originalValue) => {
					return originalValue === '' ? undefined : value
				})
				.notRequired()
		})

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm<{ google_iframe: string; google_map: string; longitude?: number; latitude?: number }>(
		{
			resolver: yupResolver(schema as any),
			mode: 'all'
		}
	)

	useEffect(() => {
		companyService.getCompanyById(companyId).then(res => {
			setValue('google_iframe', res.google_iframe)
			setValue('google_map', res.google_map)
			setValue('latitude', res.latitude)
			setValue('longitude', res.longitude)
		})
	}, [companyId])

	const onSubmit = (data: {
		google_iframe: string
		google_map: string
		longitude?: number
		latitude?: number
	}) => {
		setIsLoading(true)
		companyService
			.updateCompany(companyId, data, false)
			.then(() => {
				toast.success(t(tKey('toast.updateSuccess')))
			})
			.catch(() => toast.error(t(tKey('toast.updateError'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.googleMaps'))}
					</h1>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="google_map"
							labelText={t(tKey('labels.locationMap'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.iframe'))}
							name="google_iframe"
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							type="number"
							labelText={t(tKey('labels.longitude'))}
							name="longitude"
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							labelText={t(tKey('labels.latitude'))}
							name="latitude"
						/>
					</div>
				</div>
			</div>
			<div
				className={cn(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.SOCIALMEDIA })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</form>
	)
}

const SocialMedia = () => {
	const { id: companyId } = useParams() as { id: string }
	const { t } = useTranslation()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companies.create')

	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup
		.object<{ facebook: string; linkedin: string; instagram: string; youtube: string }>()
		.shape({
			facebook: yup.string().notRequired(),
			linkedin: yup.string().notRequired(),
			instagram: yup.string().notRequired(),
			youtube: yup.string().notRequired()
		})

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm<{ facebook: string; linkedin: string; instagram: string; youtube: string }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		companyService.getCompanyById(companyId).then(res => {
			setValue('facebook', res.facebook)
			setValue('linkedin', res.linkedin)
			setValue('instagram', res.instagram)
			setValue('youtube', res.youtube)
		})
	}, [companyId])

	const onSubmit = (data: {
		facebook: string
		linkedin: string
		instagram: string
		youtube: string
	}) => {
		setIsLoading(true)
		companyService
			.updateCompany(companyId, data, false)
			.then(() => {
				toast.success(t(tKey('toast.updateSuccess')))
			})
			.catch(() => toast.error(t(tKey('toast.updateError'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl max-md:mb-[81px] mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.socialMedia'))}
					</h1>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="facebook"
							labelText={t(tKey('labels.facebook'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.linkedin'))}
							name="linkedin"
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="instagram"
							labelText={t(tKey('labels.instagram'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.youtube'))}
							name="youtube"
						/>
					</div>
				</div>
			</div>
			<div
				className={cn(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.WORKTIME })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</form>
	)
}

export interface ScheduleForm {
	start: {
		hour: number
		minute: number
	} | null
	end: {
		hour: number
		minute: number
	} | null
	pause_start: {
		hour: number
		minute: number
	} | null
	pause_end: {
		hour: number
		minute: number
	} | null
	day: string
	type: 'Closed' | '24' | 'Custom'
	id_service: string | null
	id_company?: string
	_id?: string
	__v?: string
}

const WorkTime = () => {
	const { id: companyId } = useParams() as { id: string }
	const { t } = useTranslation()

	const tKey = getTKey('companyProfile')

	const auth = useAppSelector(state => state.auth)

	const [forceRefresh, setForceRefresh] = useState(false)
	const [time, setTime] = useState<{ [day: string]: ScheduleForm }>({})

	useEffect(() => {
		companyService.getCompanySchedule(companyId).then(res => {
			const mappedTime = res
				.map(r => ({ ...r, day: r.day.toString() }))
				.reduce((agg, curr) => ({ ...agg, [curr.day]: curr }), {})
			setTime(mappedTime)
		})
	}, [forceRefresh])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, day: string) => {
		const { name, value } = event.target
		const germanToEnglish = germanToEnglishDays[day]

		const dateTime = DateTime.fromFormat(value, 'HH:mm')
		const weekDay =
			DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday ===
			7
				? 0
				: DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday

		if (!dateTime.isValid) {
			return setTime(prev => ({
				...prev,
				[weekDay.toString()]: {
					...prev[weekDay.toString()],
					day: weekDay.toString(),
					[name]: null
				}
			}))
		}
		setTime(prev => ({
			...prev,
			[weekDay.toString()]: {
				...prev[weekDay.toString()],
				day: weekDay.toString(),
				[name]: { hour: dateTime.hour, minute: dateTime.minute }
			}
		}))
	}

	const addSchedule = (weekDay: number) => {
		const payload: ScheduleForm = {
			start: time[weekDay]?.start ?? { hour: 0, minute: 0 },
			end: time[weekDay]?.end ?? { hour: 0, minute: 0 },
			pause_start: time[weekDay]?.pause_start ?? { hour: 0, minute: 0 },
			pause_end: time[weekDay]?.pause_end ?? { hour: 0, minute: 0 },
			id_service: null,
			id_company: companyId,
			type: time[weekDay]?.type ?? 'Closed',
			day: weekDay.toString()
		}
		companyService
			.createCompanySchedule(payload)
			.then(() => {
				toast.success(t(tKey('toast.addScheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.addScheduleError'))))
	}

	const updateSchedule = (weekDay: number) => {
		const scheduleId = time[weekDay]._id as string
		delete time[weekDay].__v
		delete time[weekDay]._id
		delete time[weekDay].id_company
		companyService
			.updateCompanySchedule(scheduleId, time[weekDay])
			.then(() => {
				toast.success(t(tKey('toast.scheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.scheduleError'))))
	}

	const deleteSchedule = (weekDay: number) => {
		const scheduleId = (time[weekDay] as any)._id
		delete (time[weekDay] as any).__v
		delete (time[weekDay] as any)._id
		delete (time[weekDay] as any).id_company
		companyService
			.updateCompanySchedule(scheduleId, {
				start: null,
				end: null,
				pause_start: null,
				pause_end: null,
				day: weekDay.toString(),
				id_service: null,
				type: 'Closed'
			})
			.then(() => {
				toast.success(t(tKey('toast.scheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.scheduleError'))))
	}

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 mb-[95px] max-md:mb-[81px] flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col md:gap-y-4">
					<h1 className="text-primary p-3 md:p-6 font-domine font-bold md:text-lg">
						{t(tKey('titles.workTime'))}
					</h1>
					<table className="-mt-14 -mb-12 max-md:hidden table-auto w-full max-xl:block whitespace-nowrap overflow-x-auto border-separate border-spacing-x-[29px] border-spacing-y-[34px]">
						<thead>
							<tr>
								<th></th>
								<th className="text-primary text-lg font-semibold">{t(tKey('tables.status'))}</th>
								<th className="text-primary text-lg font-semibold">
									{t(tKey('tables.availability'))}
								</th>
								<th className="text-primary text-lg font-semibold">{t(tKey('tables.break'))}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getLocalizedWeekDays().map(day => {
								const germanToEnglish = germanToEnglishDays[day]

								const weekDay =
									DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE')
										.weekday === 7
										? 0
										: DateTime.fromFormat(
												getAppLang() === Language.EN ? day : germanToEnglish,
												'EEEE'
											).weekday

								return (
									<tr key={day}>
										<td className="px-2.5 bg-border rounded text-center text-primary font-semibold">
											{day}
										</td>
										<td className="flex w-max">
											<Select
												onChange={event =>
													setTime(prev => ({
														...prev,
														[weekDay.toString()]: {
															...prev[weekDay.toString()],
															type: event.target.value as Schedule['type']
														}
													}))
												}
												value={time[weekDay]?.type ?? 'Closed'}
												name="type">
												<option value="Custom">{t(tKey('labels.custom'))}</option>
												<option value="Closed">{t(tKey('labels.closed'))}</option>
												<option value="24">{t(tKey('labels.open'))}</option>
											</Select>
										</td>
										<td>
											<div className="flex gap-x-5">
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="start"
													type="time"
													value={
														!time[weekDay.toString()]?.start ||
														time[weekDay.toString()]?.start === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.start as Object
																).toFormat('HH:mm')
													}
													onChange={event => handleChange(event, day)}
												/>
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="end"
													value={
														!time[weekDay.toString()]?.end || time[weekDay.toString()]?.end === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.end as Object
																).toFormat('HH:mm')
													}
													type="time"
													onChange={event => handleChange(event, day)}
												/>
											</div>
										</td>

										<td>
											<div className="flex gap-x-5">
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="pause_start"
													value={
														!time[weekDay.toString()]?.pause_start ||
														time[weekDay.toString()]?.pause_start === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.pause_start as Object
																).toFormat('HH:mm')
													}
													type="time"
													onChange={event => handleChange(event, day)}
												/>
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="pause_end"
													value={
														!time[weekDay.toString()]?.pause_end ||
														time[weekDay.toString()]?.pause_end === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.pause_end as Object
																).toFormat('HH:mm')
													}
													type="time"
													onChange={event => handleChange(event, day)}
												/>
											</div>
										</td>
										<td>
											{time[weekDay]?._id ? (
												<div className="flex gap-x-4">
													<PencilIcon
														onClick={() => updateSchedule(weekDay)}
														className="h-5 w-5 cursor-pointer text-primary"
													/>
													<TrashIcon
														onClick={() => deleteSchedule(weekDay)}
														className="h-5 w-5 cursor-pointer text-[#F84C6B]"
													/>
												</div>
											) : (
												<Button onClick={() => addSchedule(weekDay)}>
													{t(tKey('buttons.add'))}
												</Button>
											)}
										</td>
									</tr>
								)
							})}
							<tr></tr>
						</tbody>
					</table>
					<div className="md:hidden px-3">
						{getLocalizedWeekDays().map((day, index) => {
							const weekDay =
								DateTime.fromFormat(day, 'EEEE').weekday === 7
									? 0
									: DateTime.fromFormat(day, 'EEEE').weekday
							return (
								<Disclosure key={day}>
									{({ open }) => (
										<>
											<Disclosure.Button
												className={cn(
													'flex justify-between w-full px-3 py-2 border-b border-border',
													{
														'bg-background': open,
														'border-b-0': index === getLocalizedWeekDays().length - 1
													}
												)}>
												<div className="flex justify-between gap-x-3 w-full">
													<div className="flex grow w-full gap-x-3 items-center">
														<ChevronRightIcon
															className={cn('h-4 w-4 text-primary shrink-0', {
																'rotate-90 transform': open
															})}
														/>
														<p className="text-primary text-sm">{day}</p>
													</div>
													<Select
														onChange={event =>
															setTime(prev => ({
																...prev,
																[weekDay.toString()]: {
																	...prev[weekDay.toString()],
																	type: event.target.value as Schedule['type']
																}
															}))
														}
														onClick={event => event.stopPropagation()}
														value={time[weekDay]?.type ?? 'Closed'}
														name="type"
														className="w-full">
														<option value="Custom">{t(tKey('labels.custom'))}</option>
														<option value="Closed">{t(tKey('labels.closed'))}</option>
														<option value="24">{t(tKey('labels.open'))}</option>
													</Select>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel>
												<div className="flex px-3 py-2 flex-col gap-y-1.5 border-b border-border bg-background">
													<h3 className="text-primary text-sm font-semibold">
														{t(tKey('tables.availability'))}
													</h3>
													<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="start"
															type="time"
															value={
																!time[weekDay.toString()]?.start ||
																time[weekDay.toString()]?.start === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.start as Object
																		).toFormat('HH:mm')
															}
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="end"
															value={
																!time[weekDay.toString()]?.end ||
																time[weekDay.toString()]?.end === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.end as Object
																		).toFormat('HH:mm')
															}
															type="time"
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
													</div>
												</div>
												<div className="flex px-3 py-2 flex-col gap-y-1.5 bg-background">
													<h3 className="text-primary text-sm font-semibold">
														{t(tKey('tables.break'))}
													</h3>
													<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="pause_start"
															type="time"
															value={
																!time[weekDay.toString()]?.pause_start ||
																time[weekDay.toString()]?.pause_start === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.pause_start as Object
																		).toFormat('HH:mm')
															}
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="pause_end"
															value={
																!time[weekDay.toString()]?.pause_end ||
																time[weekDay.toString()]?.pause_end === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.pause_end as Object
																		).toFormat('HH:mm')
															}
															type="time"
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
													</div>
												</div>
												{time[weekDay]?._id ? (
													<div className="bg-[#D3E3F1B2] text-primary grid auto-cols-auto grid-flow-col divide-x divide-gray-400 py-2 px-3 border-b border-border">
														<button
															className="cursor-pointer"
															onClick={() => updateSchedule(weekDay)}>
															{t(tKey('buttons.add'))}
														</button>
														<button
															className="cursor-pointer"
															onClick={() => deleteSchedule(weekDay)}>
															{t(tKey('buttons.clear'))}
														</button>
													</div>
												) : (
													<button
														onClick={() => addSchedule(weekDay)}
														className="bg-[#D3E3F1B2] w-full text-center text-primary py-2 px-3 border-b border-border">
														{t(tKey('buttons.add'))}
													</button>
												)}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
