import { DateTime, WeekdayNumbers } from 'luxon'

import { getAppLang } from '@/utils/language'

export const getTimeRange = (
	startTime: number,
	endTime: number,
	pauseStart: number,
	pauseEnd: number,
	isToday: boolean,
	duration: string,
	day: number,
	minimumBookingTime: number
): { [key: number]: string[] } => {
	const timeSlots: { [key: number]: string[] } = {}
	if (!duration || !minimumBookingTime) return undefined as any

	if (startTime === endTime) {
		const timeArray: string[] = []
		for (let i = 0; i < 24 * 60; i += Number(duration)) {
			const currentTime = startTime + i * 60 * 1000
			const dateTime = DateTime.fromMillis(currentTime)
			if (
				(!isToday ||
					(isToday &&
						dateTime.toMillis() >=
							DateTime.now().plus({ hours: minimumBookingTime }).toMillis())) &&
				!isWithinPauseRange(dateTime, pauseStart, pauseEnd)
			) {
				timeArray.push(dateTime.toFormat('HH:mm'))
			}
		}
		timeSlots[day] = timeArray
	} else {
		const timeArray: string[] = []
		let currentTime = startTime

		while (currentTime <= endTime) {
			const dateTime = DateTime.fromMillis(currentTime)
			if (
				(!isToday ||
					(isToday &&
						dateTime.toMillis() >=
							DateTime.now().plus({ hours: minimumBookingTime }).toMillis())) &&
				!isWithinPauseRange(dateTime, pauseStart, pauseEnd)
			) {
				timeArray.push(dateTime.toFormat('HH:mm'))
			}

			currentTime += Number(duration) * 60 * 1000
		}
		timeSlots[day] = timeArray
	}
	return timeSlots
}

const isWithinPauseRange = (dateTime: DateTime, pauseStart: number, pauseEnd: number): boolean => {
	const currentTimeMillis = dateTime.toMillis()
	return currentTimeMillis >= pauseStart && currentTimeMillis <= pauseEnd
}

export const appendDateTime = (date: string, timeSlot: string) => {
	const dateTime = DateTime.fromISO(date)
	const timeArray = timeSlot.split(':')
	const hour = parseInt(timeArray[0])
	const minute = parseInt(timeArray[1].split(' ')[0])

	const dateTimeWithTime = dateTime.set({ hour, minute })

	return dateTimeWithTime.toMillis()
}

export const getLocalizedWeekDays = () => {
	const daysOfWeek = []
	for (let i = 1; i <= 7; i++) {
		const day = DateTime.local()
			.setLocale(getAppLang())
			.set({ weekday: i as WeekdayNumbers })
			.toFormat('EEEE')
		daysOfWeek.push(day)
	}

	return daysOfWeek
}

export const getDuration = (dateValue: string): string => {
	const start = DateTime.fromISO(dateValue, { zone: 'utc' }).toLocal()
	const end = DateTime.now()

	const diff = end.diff(start, ['years', 'months', 'days', 'hours', 'minutes']).toObject()

	if (diff.years && diff.years > 0) {
		return `${diff.years}y`
	} else if (diff.months && diff.months > 0) {
		return `${diff.months}months`
	} else if (diff.days && diff.days > 0) {
		return `${diff.days}d`
	} else if (diff.hours && diff.hours > 0) {
		return `${diff.hours}h`
	} else if (diff.minutes && diff.minutes > 0) {
		return `${diff.minutes.toFixed(0)}m`
	}

	return ''
}

export const getMonths = () => {
	const months = Array.from({ length: 12 }, (_, i) =>
		DateTime.local()
			.set({ month: i + 1 })
			.setLocale(getAppLang())
			.toFormat('MMMM')
	)
	return months
}

export const getYears = () => {
	const years = []
	for (let year = 1970; year <= 2050; year++) {
		years.push(year.toString())
	}
	return years
}
