import { CompanyTypeForm } from '@/pages/company-types/create'
import axios from '@/services/axios'

const getCompanyTypes = (): Promise<CompanyType[]> => {
	return axios.get('/all/types').then(response => response.data)
}

const getCompanyTypeById = (companyTypeId: string): Promise<CompanyType> => {
	return axios.get(`/company-type/${companyTypeId}`).then(response => response.data)
}

const createCompanyType = (data: CompanyTypeForm) => {
	return axios.post('/company-type', data).then(response => response.data)
}

const updateCompanyType = (companyTypeId: string, data: CompanyTypeForm) => {
	return axios.put(`/company-type/${companyTypeId}`, data).then(response => response.data)
}

const deleteCompanyType = (companyTypeId: string) => {
	return axios.delete(`/company-type/${companyTypeId}`).then(response => response.data)
}

const companyTypeService = {
	getCompanyTypes,
	getCompanyTypeById,
	createCompanyType,
	updateCompanyType,
	deleteCompanyType
}

export default companyTypeService
