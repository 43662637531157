import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { Input } from '@/components/inputs/input'
import { Select } from '@/components/inputs/select'
import { Spinner } from '@/components/spinner'
import { Status } from '@/constants'
import companyTypeService from '@/services/company-type-service'
import { getTKey } from '@/utils/language'

interface CreateCompanyTypeProps {
	onCreation: () => void
	onCancel: () => void
	companyTypeId: string | null
	className?: string
}

export interface CompanyTypeForm {
	label: string
	is_active: string
}

export const CreateCompanyType = ({
	onCreation,
	onCancel,
	companyTypeId,
	className
}: CreateCompanyTypeProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('companyTypes.create')

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<CompanyTypeForm>().shape({
		label: yup.string().required(t(tKey('errors.name'))),
		is_active: yup.string().required(t(tKey('errors.isActive')))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<CompanyTypeForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			label: '',
			is_active: Status.ACTIVE
		},
		mode: 'all'
	})

	useEffect(() => {
		if (companyTypeId) {
			companyTypeService.getCompanyTypeById(companyTypeId).then(res =>
				reset({
					label: res.label,
					is_active: res.is_active
				})
			)
		}
	}, [companyTypeId])

	const onSubmit = (data: CompanyTypeForm) => {
		setIsLoading(true)
		if (companyTypeId) {
			companyTypeService
				.updateCompanyType(companyTypeId, data)
				.then(res => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.updateError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			companyTypeService
				.createCompanyType(data)
				.then(res => {
					toast.success(t(tKey('toast.companyTypeSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.companyTypeError')))
				})
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={clsx('md:px-8 py-6 max-md:px-5', className)}>
			<div className="flex flex-col gap-y-5">
				<div className="flex flex-col gap-y-5">
					<Input
						register={register}
						errors={errors}
						name="label"
						labelText={t(tKey('labels.name'))}
					/>
					<Select
						labelText={t(tKey('labels.status'))}
						name="is_active"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.status'))}</option>
						<option value="aktiv">{t(tKey('labels.active'))}</option>
						<option value="inaktiv">{t(tKey('labels.inactive'))}</option>
					</Select>
				</div>

				<div className="flex mt-5 gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{companyTypeId ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
