import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = { isSidebarVisible: true }

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState: initialState,
	reducers: {
		setSidebarVisibility(_state, action: PayloadAction<boolean>) {
			return { isSidebarVisible: action.payload }
		}
	}
})

const { reducer } = sidebarSlice

export const { setSidebarVisibility } = sidebarSlice.actions

export default reducer
